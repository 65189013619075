import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import Form from "react-bootstrap/Form";
import TelegramIcon from "../assets/svg/TelegramIcon";
import WhatsappIcon from "../assets/svg/WhatsappIcon";
import EyeIcon from "../assets/svg/EyeIcon";
import { useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import GamesIcon from "../assets/svg/GamesIcon";
import HomeIcon from "../assets/svg/HomeIcon";
// import RiExchangeFill from "../assets/svg/ProfileIcon";
import SportsIcon from "../assets/svg/SportsIcon";
import CashierIcon from "../assets/svg/CashierIcon";
import logo from "../assets/svg/logo_new.png";
import BotHeadIcon1 from "../assets/images/icons/BotHeadIcon1.png";
import BotHeadIcon2 from "../assets/images/icons/BotHeadIcon2.png";
import BotHeadIcon3 from "../assets/images/icons/BotHeadIcon3.png";
import BotHeadIcon4 from "../assets/images/icons/BotHeadIcon4.png";
import BotHeadIcon5 from "../assets/images/icons/BotHeadIcon5.png";
import BotHeadIcon6 from "../assets/images/icons/BotHeadIcon6.png";
import BotHeadIcon7 from "../assets/images/icons/BotHeadIcon7.png";
import { useSelector } from "react-redux";

import SportsIcon1 from "../assets/images/games-icon/Ezugi.png";
import SportsIcon2 from "../assets/images/games-icon/Evolution.png";
import SupportIcon from "../assets/images/games-icon/Support.png";

import Casino1 from "../assets/images/games-icon/casinoezugi-icon.png";
import Sports1 from "../assets/images/games-icon/evolution-icon.png";
import Slots1 from "../assets/images/games-icon/Slots.png";
import Evolution from "../assets/images/games-icon/Ezugi.png";
import SpribeIcon from "../assets/images/games-icon/aviator.png";
import StaticLogo from "../assets/images/staticlogo.png";
import xpg from "../assets/images/games-icon/xpg1.png";
import kingmaker from "../assets/images/games-icon/crown.png";
import supern from "../assets/images/games-icon/Supernova1.png";

import SportsbookIcon from "../assets/images/games-icon/sportsbook.png";
import Exchange from "../assets/images/games-icon/exchange.png";
import { getNotifications } from "../redux/app/actions";
import { AiOutlineClose } from "react-icons/ai";

const Header = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [websiteNotifications, setWebsiteNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(true);
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);
  const loadNotifications = async () => {
    // get notifications
    const notifications = await getNotifications();
    if (notifications && notifications?.data?.data) {
      setShowNotifications(true);
      setWebsiteNotifications(notifications?.data?.data);
    }
  };
  useEffect(() => {
    loadNotifications();
  }, []);
  return (
    <>
      {" "}
      {(websiteNotifications.length && showNotifications && (
        <div className="marqu">
          <marquee>
            {websiteNotifications.map((notf, idx) => (
              <label style={{ marginRight: "30px" }}>{notf?.content}</label>
            ))}
          </marquee>
          <span onClick={() => setShowNotifications(false)}>
            <AiOutlineClose />
          </span>
        </div>
      )) ||
        null}
      <header className="header beforeheader">
        <div className="top_head">
          <Container fluid>
            <Row>
              <Col xs={12}>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <div className="logo">
                      <a href="/">
                        <img src={appDetails?.LOGO_URL} alt="Logo" />
                      </a>
                    </div>
                  </div>
                  <div className="staticLogo">
                    <img src={StaticLogo} />
                  </div>
                  <div className="middle-part">
                    <Navbar expand="lg">
                      <Container>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                          <Nav className="me-auto">
                            <Nav.Link href="/sign-in">
                              <img src={Casino1} alt="bottom head link icon" />
                              <span>Ezugi</span>
                            </Nav.Link>
                            <Nav.Link href="/sign-in">
                              <img
                                src={Evolution}
                                alt="bottom head link icon"
                              />
                              <span>Evolution</span>
                            </Nav.Link>
                            <Nav.Link href="/sports/Cricket">
                              <img
                                src={Exchange}
                                alt="Exchange Icon"
                                width={20}
                              />{" "}
                              Exchange
                            </Nav.Link>
                            <Nav.Link href="/betby">
                              {" "}
                              <img src={SportsbookIcon} alt="Sportsbook" />{" "}
                              Sportsbook{" "}
                            </Nav.Link>

                            <Nav.Link href="/sign-in">
                              <img src={Slots1} alt="bottom head link icon" />
                              <span>Slots</span>
                            </Nav.Link>
                            <Nav.Link href="/casino/supernowa">
                              <img src={supern} alt="bottom head link icon" />
                              <span>Supernowa</span>
                            </Nav.Link>
                            <Nav.Link href="/casino/xpg">
                              <img src={xpg} alt="bottom head link icon" />
                              <span>Xpg</span>
                            </Nav.Link>
                            <Nav.Link href="/kingmaker">
                              <img
                                src={kingmaker}
                                alt="bottom head link icon"
                              />
                              <span>Kingmaker</span>
                            </Nav.Link>
                            {/* <Nav.Link href="/sign-in">
                            <img src={SpribeIcon} alt="bottom head link icon" />
                            <span>Spribe</span>
                          </Nav.Link> */}
                          </Nav>
                        </Navbar.Collapse>
                      </Container>
                    </Navbar>
                  </div>
                  <div className="headerRight">
                    {/* <ul className="social">
                    <li>
                      <a target={"_blank"} href={`${appDetails?.TELEGRAM}`}>
                        <TelegramIcon />
                      </a>
                    </li>
                    <li>
                      <div
                        onClick={() =>
                          window.open(`https://wa.me/+917888846060`, "_blank")
                        }
                      >
                        <WhatsappIcon />
                      </div>
                    </li>
                  </ul> */}
                    <Button
                      variant="primary"
                      onClick={() => {
                        navigate("/sign-in");
                      }}
                      className="button-primary btn_signin"
                    >
                      Sign In
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => {
                        navigate("/mobile-number");
                      }}
                      className="button-primary btn_signup ms-2"
                    >
                      Sign Up
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="bottom_head d-none">
          <Container fluid>
            <Row>
              <Col xs={12} className="px-0">
                <ul>
                  {/* <li>
                  <a href="/sign-in">
                    <img src={BotHeadIcon1} alt="bottom head link icon" />
                    <span>All Live Games</span>
                  </a>
                </li> */}
                  {/* <li>
                  <a href="/sign-in">
                    <img src={BotHeadIcon2} alt="bottom head link icon" />
                    <span>Exchange</span>
                  </a>
                </li> */}
                  <li>
                    <a href="/sign-in">
                      <img src={Casino1} alt="bottom head link icon" />
                      <span>Ezugi Games</span>
                    </a>
                  </li>

                  {/* <li>
                  <a href="/sign-in">
                    <img src={BotHeadIcon5} alt="bottom head link icon" />
                    <span>From Casino</span>
                  </a>
                </li> */}
                  <li>
                    <a href="/sign-in">
                      <img src={Slots1} alt="bottom head link icon" />
                      <span>Slots</span>
                    </a>
                  </li>
                  <li>
                    <a href="/sign-in">
                      <img src={Evolution} alt="bottom head link icon" />
                      <span>Evolution</span>
                    </a>
                  </li>
                  {/* <li>
                  <a href="/sign-in">
                    <img src={BotHeadIcon7} alt="bottom head link icon" />
                    <span>New Arrivals</span>
                  </a>
                </li> */}
                </ul>
              </Col>
            </Row>
          </Container>
        </div>

        {/* <Offcanvas
        className={"steps-canvas"}
        show={show}
        onHide={handleClose}
        placement={"end"}
      >
        <Offcanvas.Header closeButton="closeButton" closeVariant="white">
          <Offcanvas.Title>
            <span className="top-heading">Sign in now!</span>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="form-steps">
            <h2 className="h2">Hello Again!</h2>
            <p>
              Welcome back, you've
              <br />
              been missed!
            </p>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Control type="text" placeholder="Enter Username*" />
              </Form.Group>
              <Form.Group
                className="mb-3 position-relative"
                controlId="formBasicPassword"
              >
                <Form.Control type="password" placeholder="Password*" />
                <span className="icon">
                  <EyeIcon />
                </span>
              </Form.Group>
              <div className="d-flex justify-content-end mb-3">
                <a className="form-link" href="#">
                  Password Recovery
                </a>
              </div>
              <Button variant="primary" type="submit">
                Sign in
              </Button>
            </Form>
            <div className="sign-up mt-4">
              Not a member?
              <br />
              <a href="register">Sign Up Now</a>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas> */}
      </header>
    </>
  );
};

export default Header;
